import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/administrator',
    name: 'administrator',
    component: () => import('../views/AdministratorView.vue')
  },
  {
    path: '/admin_project/:project_id',
    name: 'admin_project',
    component: () => import('../views/AdminProjectView.vue')
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import('../views/SalesView.vue')
  },
  {
    path: '/sales_product',
    name: 'sales_product',
    component: () => import('../views/SalesProductView.vue')
  },
  {
    path: '/sales_payment/:transaction_id',
    name: 'sales_payment',
    component: () => import('../views/SalesPaymentView.vue')
  },
  {
    path: '/sales_print/:transaction_id',
    name: 'sales_print',
    component: () => import('../views/SalesPrintView.vue')
  },
  {
    path: '/struk/:transaction_id',
    name: 'struk',
    component: () => import('../views/SalesJustPrintView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
