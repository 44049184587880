import moment from 'moment';

const filters = {
  formatDate(input,format = 'MM/DD/YYYY hh:mm') {
    return moment(String(input)).format(format);
  },
  formatPrice(input) {
    return Number(input).toLocaleString();
  }
}
export default filters;