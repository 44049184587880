import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import money from 'v-money3';
import filters from './services/Filters';

import("./assets/icon/style.css");
import("./assets/css/mobile.css");

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

var app = createApp(App)

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$filters = filters;
var url_http = window.location.origin+'/';
if (url_http.includes('localhost')) {
	url_http = 'http://localhost/wf_backend/';
}

app.config.globalProperties.$site_url = url_http+'api/';
app.use(router);
app.use(money);
app.component('VueDatePicker', VueDatePicker);
app.mount('#app');